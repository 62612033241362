import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import {
  CanAccess,
  useCreateMany,
  useDelete,
  useList,
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@refinedev/core";

import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Flex,
  Form,
  Image,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
  Upload,
  UploadFile,
  theme,
} from "antd";
import { UploadProps } from "antd/lib";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { SingleElementForm, Text } from "../../../components";
import CategoryAutoselect from "../../../components/shop/CategoryAutoselect";
import VariantTree from "../../../components/shop/VariantTree";
import { useUser } from "../../../contexts/ContextProvider";
import { useDeleteForJunctionTableMany as deleteMany } from "../../../hooks/useDeleteForJunctionTable";
import useFile from "../../../hooks/useFile";
import { IMedia } from "../../../interfaces/general.interface";
import { Category } from "../../../interfaces/shop.interface";
import { supabaseClient } from "../../../utility";
import { updateCategoryPathnames } from "../../../utility/shop";
import SubscriptionProductsList from "../subscription-products/list";
import ProductIssuesList from "./product-issues/list";

const ProductItemShow: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeForm, setActiveForm] = useState<
    | "title"
    | "description"
    | "categories"
    | "images"
    | "netto_price"
    | "sales_tax"
    | "billing_interval"
    | "configured_variants"
  >();
  const [fileList, setFileList] = React.useState<UploadFile[] | undefined>([]);
  const [period, setPeriod] = React.useState();
  const [items, setItems] = React.useState<any>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [imagesLoading, setImagesLoading] = React.useState(true);
  const { currentTeam } = useUser();

  const primaryColor = theme?.useToken()?.token?.colorPrimary;
  const { list } = useNavigation();
  const { mutate: deleteMutation } = useDelete();
  const { mutateAsync: updateAsync } = useUpdate();
  const translate = useTranslate();
  const params = useParams();

  const { getFileDataURL, removeFile } = useFile();

  const { query } = useShow<{
    [key: string]: any;
    product_categories: Category[];
  }>({
    resource: "product_items",
    id: params?.id,
    meta: { select: "*, product_categories(*)" },
  });
  const { data, isLoading, isError } = query;
  const itemsCategory = useMemo(
    () => data?.data.product_categories.map((item) => item.id),
    [data?.data]
  );
  const { data: categoryList } = useList<Category>({
    resource: "product_categories",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const categoriesWithPath = useMemo(
    () =>
      updateCategoryPathnames(categoryList?.data ?? []).filter((item) =>
        itemsCategory?.includes(item.id)
      ),
    [categoryList?.data, itemsCategory]
  );
  const { mutateAsync: createMany } = useCreateMany();

  const closeModal = () => {
    list("product_items");
  };

  const getAllImages = React.useCallback(async () => {
    try {
      setImagesLoading(true);
      const responses = await Promise.allSettled(
        data?.data.images.map(async (image: IMedia) => {
          try {
            const fetchedImg = await getFileDataURL(image, true);
            if (!fetchedImg) {
              throw new Error("Fehler beim Lesen der Datei");
            }
            if (fetchedImg && typeof fetchedImg === "object") {
              return {
                uid: image?.id ?? "",
                lastModified: fetchedImg.file.lastModified,
                lastModifiedDate: fetchedImg.file.lastModified,
                name: fetchedImg.file.name,
                size: fetchedImg.file.size,
                type: fetchedImg.blob.type,
                percent: 100,
                originFileObj: {
                  uid: image?.id ?? "",
                },
                status: "done",
                response: "ok",
                thumbUrl: fetchedImg.base64,
              };
            }
          } catch (error: any) {
            console.error(
              `Error fetching image with id ${image?.id}: ${error.message}`
            );
            return null;
          }
        })
      );

      const successfulResponses = responses
        .filter(
          (result): result is PromiseFulfilledResult<any> =>
            result.status === "fulfilled" && result.value !== null
        )
        .map((result) => result.value);

      setFileList(successfulResponses);
    } catch (error: any) {
      console.error("Error in getAllImages:", error.message);
    } finally {
      setImagesLoading(false);
    }
  }, [data?.data.images, getFileDataURL]);

  useEffect(() => {
    getAllImages();
  }, [data?.data.images, getAllImages, getFileDataURL]);

  if (isError) {
    closeModal();
    return null;
  }

  if (isLoading) {
    return (
      <Drawer
        open
        styles={{
          body: {
            background: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        className="entityDrawer"
      >
        <Spin />
      </Drawer>
    );
  }

  const {
    id,
    title,
    description,
    images,
    netto_price,
    sales_tax,
    billing_interval,
    invoice_interval,
    currency,
    created_at,
    type,
    configured_variants,
  } = data?.data ?? {};

  const selectAfter = (
    <Form.Item name={"currency"} style={{ margin: 0 }}>
      <Select defaultValue="USD" style={{ width: 60 }}>
        <Select.Option value="USD">$</Select.Option>
        <Select.Option value="EUR">€</Select.Option>
        <Select.Option value="GBP">£</Select.Option>
        <Select.Option value="CNY">¥</Select.Option>
      </Select>
    </Form.Item>
  );
  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter((file) => file.status !== "error"));
  const onRemove = async (file) => {
    await removeFile([`public/shop/product_items/${file.name}`]).then(
      async () => {
        if (params.id) {
          fileList?.filter((file) => {
            return !images.find((item) => item.id == file.uid);
          });
          const restImages = images.filter((item) => item.id != file.uid);
          updateAsync({
            resource: "product_items",
            id: params?.id,
            values: { images: restImages ?? [] },
          });
        }
      }
    );
    const index = fileList?.indexOf(file);
    const newFileList = fileList?.slice();
    newFileList?.splice(index ?? 0, 1);

    setFileList(newFileList);
  };
  const dummyRequest = async (options: any) => {
    const { onSuccess } = options;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = file.thumbUrl;
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const props: UploadProps = {
    onChange: handleChange,
    multiple: true,
    customRequest: dummyRequest,
    onRemove: onRemove,
    listType: "picture-card",
    accept: "image/png, image/jpeg, image/jpg, image/webp",
    maxCount: 5,
    onPreview: handlePreview,
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Drawer
          open
          onClose={() => closeModal()}
          className="entityDrawer"
          title={translate("product_items.show.title")}
          footer={
            <Flex justify="space-between" align="center">
              <Text className="ant-text tertiary">
                {translate("product_items.fields.created_at")}{" "}
                {dayjs(created_at).format(
                  translate("page.settings.date_format")
                )}{" "}
                {translate("page.settings.clock")}
              </Text>

              <Popconfirm
                title={translate("product_items.buttons.delete.title")}
                description={translate("product_items.buttons.delete_single")}
                onConfirm={() => {
                  if (id) {
                    deleteMutation(
                      {
                        id,
                        resource: "product_items",
                      },
                      {
                        onSuccess: () => closeModal(),
                      }
                    );
                  }
                }}
                okText={translate("buttons.yes", "Yes")}
                cancelText={translate("buttons.no", "No")}
              >
                <Button type="link" danger icon={<DeleteOutlined />}>
                  {translate("product_items.buttons.delete.title")}
                </Button>
              </Popconfirm>
            </Flex>
          }
        >
          <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
            <Card
              title={
                <>
                  <Text>{translate("product_items.show.basic")}</Text>
                </>
              }
            >
              <SingleElementForm
                state={
                  activeForm && activeForm === "title"
                    ? "form"
                    : title
                    ? "view"
                    : "empty"
                }
                itemProps={{
                  name: "title",
                  label: translate("product_items.fields.title"),
                  initialValue: title,
                }}
                useFormProps={{
                  resource: "product_items",
                  id: id,
                }}
                view={<Text>{title}</Text>}
                onClick={() => setActiveForm("title")}
                onUpdate={() => setActiveForm(undefined)}
                onCancel={() => setActiveForm(undefined)}
              >
                <Input />
              </SingleElementForm>
              <SingleElementForm
                state={
                  activeForm && activeForm === "description"
                    ? "form"
                    : description
                    ? "view"
                    : "empty"
                }
                itemProps={{
                  name: "description",
                  label: translate("product_items.fields.description"),
                  initialValue: description,
                }}
                useFormProps={{
                  resource: "product_items",
                  id: id,
                }}
                view={<Text>{description}</Text>}
                onClick={() => setActiveForm("description")}
                onUpdate={() => setActiveForm(undefined)}
                onCancel={() => setActiveForm(undefined)}
              >
                <Input.TextArea />
              </SingleElementForm>
              <SingleElementForm
                state={
                  activeForm && activeForm === "categories"
                    ? "form"
                    : categoriesWithPath?.length > 0
                    ? "view"
                    : "empty"
                }
                itemProps={{
                  name: false,
                  label: translate("product_categories.belongs_to_categories"),
                }}
                view={
                  <Space direction="vertical">
                    {categoriesWithPath.map((category) => {
                      return (
                        <Typography.Text>{category.pathname}</Typography.Text>
                      );
                    })}
                  </Space>
                }
                formProps={{
                  async onFinish(values) {
                    const valuesToDelete = categoriesWithPath
                      .filter(
                        (item) => !values.product_categories.includes(item.id)
                      )
                      .map((category) => ({
                        product_category: category.id,
                        product_item: Number(params?.id),
                      }));

                    const valuesToAdd = values.product_categories
                      .filter(
                        (item) =>
                          !categoriesWithPath
                            .map((cat) => cat.id)
                            .includes(item)
                      )
                      .map((category) => {
                        return {
                          product_category: category,
                          product_item: params?.id,
                          account: currentTeam?.account_id,
                        };
                      });

                    if (valuesToDelete?.length) {
                      await deleteMany(
                        "product_item_has_product_category",
                        valuesToDelete
                      );
                    }

                    if (valuesToAdd.length) {
                      await createMany({
                        resource: "product_item_has_product_category",
                        values: valuesToAdd,
                      });
                    }
                  },
                }}
                useFormProps={{
                  resource: "product_items",
                  id: id,
                }}
                onClick={() => setActiveForm("categories")}
                onUpdate={() => setActiveForm(undefined)}
                onCancel={() => setActiveForm(undefined)}
                extra={
                  <CategoryAutoselect
                    multiple
                    itemProps={{
                      name: "product_categories",
                      initialValue: categoriesWithPath.map((category) =>
                        Number(category.id)
                      ),
                      label: false,
                    }}
                  />
                }
              />
              <SingleElementForm
                state={
                  activeForm && activeForm === "images"
                    ? "form"
                    : images
                    ? "view"
                    : "empty"
                }
                itemProps={{
                  name: "images",
                  label: translate("product_items.fields.images"),
                  initialValue: images,
                }}
                useFormProps={{
                  resource: "product_items",
                  id: id,
                }}
                formProps={{
                  async onFinish() {
                    const newFiles = fileList?.filter((file) => {
                      return !images.find((item) => item.id == file.uid);
                    });
                    const fileArr = newFiles
                      ? await Promise.all(
                          newFiles.map(async (item: any) => {
                            const fileName = `${Date.now()}-${item.name}`;
                            const { data: fileData, error } =
                              await supabaseClient.storage
                                .from("files")
                                .upload(
                                  `teams/${currentTeam?.account_id}/shop/product_items/${params?.id}/${fileName}`,
                                  item.originFileObj,
                                  {
                                    //test
                                  }
                                );
                            if (error) {
                              throw error;
                            } else {
                              return {
                                ...fileData,
                                name: fileName,
                              };
                            }
                          })
                        )
                      : null;

                    if (fileArr && fileArr[0] != undefined) {
                      updateAsync({
                        resource: "product_items",
                        id: params?.id ?? "",
                        values: { images: [...images, ...fileArr] },
                        mutationMode: "pessimistic",
                      });
                    }
                  },
                }}
                view={
                  <Space direction="vertical" size={"small"}>
                    {
                      <Upload {...props} disabled fileList={fileList}>
                        {!fileList?.length ? (
                          imagesLoading ? (
                            <>
                              <Spin />
                            </>
                          ) : (
                            <>no images</>
                          )
                        ) : null}
                      </Upload>
                    }
                  </Space>
                }
                onClick={() => setActiveForm("images")}
                onUpdate={() => setActiveForm(undefined)}
                onCancel={() => setActiveForm(undefined)}
                extra={
                  <Form.Item
                    // name={"images"}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    label={translate("product_items.fields.upload_image")}
                  >
                    <Upload {...props} fileList={fileList}>
                      {fileList && fileList.length < 6 && (
                        <>
                          <PlusCircleFilled
                            style={{
                              fontSize: "25px",
                              color: primaryColor,
                            }}
                          />
                        </>
                      )}
                    </Upload>
                  </Form.Item>
                }
              />
              {/*<SingleElementForm
              state={
                activeForm && activeForm === "netto_price"
                  ? "form"
                  : netto_price
                  ? "view"
                  : "empty"
              }
              itemProps={{
                name: "netto_price",
                label: translate("product_items.fields.netto_price"),
                initialValue: netto_price,
              }}
              useFormProps={{
                resource: "product_items",
                id: id,
              }}
              view={
                <Text>
                  {numberToCurrency(netto_price, currency?.toLowerCase())}
                </Text>
              }
              onClick={() => setActiveForm("netto_price")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
              extra={
                <Form.Item name={"netto_price"}>
                  <InputNumber<number>
                    precision={2}
                    decimalSeparator=","
                    parser={(value) =>
                      value?.replace(/\$\s?|(,*)/g, "") as unknown as number
                    }
                    addonAfter={selectAfter}
                  />
                </Form.Item>
              }
            />
            <SingleElementForm
              state={
                activeForm && activeForm === "sales_tax"
                  ? "form"
                  : sales_tax
                  ? "view"
                  : "empty"
              }
              itemProps={{
                name: "sales_tax",
                label: translate("product_items.fields.sales_tax"),
                initialValue: sales_tax,
              }}
              useFormProps={{
                resource: "product_items",
                id: id,
              }}
              view={<Text>{sales_tax}%</Text>}
              onClick={() => setActiveForm("sales_tax")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
            >
              <InputNumber addonAfter="%" max={100} min={0} />
            </SingleElementForm>
            <SingleElementForm
              state={
                activeForm && activeForm === "billing_interval"
                  ? "form"
                  : billing_interval
                  ? "view"
                  : "empty"
              }
              itemProps={{
                label: translate("product_items.fields.billing_interval"),
                initialValue: billing_interval,
              }}
              useFormProps={{
                resource: "product_items",
                id: id,
              }}
              view={
                <Text>
                  {invoice_interval},{" "}
                  {translate(
                    `product_items.fields.billing_interval_enum.${billing_interval}`
                  )}
                </Text>
              }
              onClick={() => setActiveForm("billing_interval")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
              extra={
                <Flex justify="flex-start">
                  <Col>
                    <Form.Item
                      name="invoice_interval"
                      label={translate("product_items.fields.invoice_interval")}
                      initialValue={invoice_interval}
                    >
                      <InputNumber
                        max={
                          period == "day"
                            ? 7
                            : period == "week"
                            ? 4
                            : period == "month"
                            ? 12
                            : 1
                        }
                        min={1}
                        placeholder={translate(
                          "product_items.fields.invoice_interval"
                        )}
                        // style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="billing_interval"
                      label={translate("product_items.fields.billing_interval")}
                      initialValue={billing_interval}
                    >
                      <Select
                        style={{ width: "100%", margin: "2px 0 0 0" }}
                        onChange={(value) => {
                          setPeriod(value);
                        }}
                      >
                        <Select.Option value="day">
                          {translate(
                            "product_items.fields.billing_interval_enum.day"
                          )}
                        </Select.Option>
                        <Select.Option value="week">
                          {translate(
                            "product_items.fields.billing_interval_enum.week"
                          )}
                        </Select.Option>
                        <Select.Option value="month">
                          {translate(
                            "product_items.fields.billing_interval_enum.month"
                          )}
                        </Select.Option>
                        <Select.Option value="year">
                          {translate(
                            "product_items.fields.billing_interval_enum.year"
                          )}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Flex>
              }
            />*/}
              <SingleElementForm
                state={
                  activeForm && activeForm === "configured_variants"
                    ? "form"
                    : configured_variants
                    ? "view"
                    : "empty"
                }
                itemProps={{
                  label: translate("product_items.fields.configured_variants"),
                  initialValue: configured_variants,
                }}
                useFormProps={{
                  resource: "product_items",
                  id: id,
                }}
                formProps={{
                  onFinish() {
                    updateAsync({
                      resource: "product_items",
                      id,
                      values: {
                        configured_variants: items?.length > 0 ? items : null,
                      },
                    });
                  },
                }}
                view={configured_variants?.map((item) => (
                  <Row style={{ marginBottom: 10 }}>
                    <Space size={"small"}>
                      <Text>{item.name}:</Text>
                      <div>
                        {item?.products_property_values?.map((val) => (
                          <Tag>{val.value}</Tag>
                        ))}
                      </div>
                    </Space>
                  </Row>
                ))}
                onClick={() => setActiveForm("configured_variants")}
                onUpdate={() => setActiveForm(undefined)}
                onCancel={() => setActiveForm(undefined)}
                extra={
                  <>
                    <VariantTree
                      currentTeam={currentTeam}
                      setItems={(val) => setItems(val)}
                      initialSelectedItems={configured_variants}
                    />
                  </>
                }
              />
            </Card>
            <SubscriptionProductsList />
            {type == "issue" && <ProductIssuesList />}
          </Space>
          {children}
        </Drawer>

        <div>
          {previewImage && (
            <Image
              wrapperStyle={{ display: "none" }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
        </div>
      </>
    </CanAccess>
  );
};
export default ProductItemShow;
