import {
  ArrowRightOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Popover,
  Select,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useProductSelect } from "../../hooks/useProductSelect";
import {
  BillingType,
  IItemProduct,
  IPhase,
} from "../../interfaces/phase.interface";
import { Text } from "../text";

type Props = {
  data?: any;
  handleChange: (props: any) => void;
  handlePhaseDelete: (props: any) => void;
  isSubscription?: boolean;
  currency?: any;
  index?: number | undefined;
};
export default function SinglePhase({
  data,
  handleChange,
  handlePhaseDelete,
  isSubscription,
  currency,
  index,
}: Props) {
  const [phase, setPhase] = useState<IPhase>(data);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingDescription, setEditingDescription] = useState<string>("");
  const [showSelectProduct, setShowSelectProduct] = useState<boolean>(false);
  const [initialTrigger, setInitialTrigger] = useState<boolean>(false);
  const [disableFromValue, setDisableFromValue] = useState<boolean>(
    phase.start === BillingType.Start
  );

  const [disableToValue, setDisableToValue] = useState<boolean>(
    phase.end === BillingType.Forever
  );
  const [newProduct, setNewProduct] = useState<IItemProduct>();
  const translate = useTranslate();
  const { selectProps, query } = useProductSelect();

  useEffect(() => {
    setPhase(data);
  }, [data]);

  const handleIssuesChange = (index: number, value: number) => {
    const newProductItems = [...phase.products];
    newProductItems[index].issues = value;
    setPhase({ ...phase, products: newProductItems });
  };
  const handleRangeChange = (value: dayjs.Dayjs[]) => {
    const newPhaseDuration = phase;
    if (newPhaseDuration) {
      newPhaseDuration.start_date = value[0].format("YYYY-MM-DD");
      newPhaseDuration.end_date = value[1].format("YYYY-MM-DD");
    }
    setPhase({ ...newPhaseDuration });
  };

  const handleSelectProduct = (e: any) => {
    const newProd = query.data?.data.find((element) => element.id === e);
    if (newProd) {
      const prod: IItemProduct = {
        reference_id: newProd.id as number,
        name: newProd.title,
        description: newProd.description,
        price_per_item: newProd.netto_price,
        tax_rate: newProd.sales_tax as number,
        issues: newProd.quantity || 1,
        variant: newProd?.variant || null,
        type: "issue",
      };
      setNewProduct(prod);
    }
  };
  const handleAddNewProduct = () => {
    if (newProduct) {
      const newProductItems = [...phase.products, newProduct];
      setPhase({ ...phase, products: newProductItems });
      setNewProduct(undefined);
      setShowSelectProduct(false);
    }
  };
  const handleDeleteProduct = (index: number) => {
    const newProductItems = phase.products.filter((_item, i) => i !== index);
    setPhase({ ...phase, products: newProductItems });
  };
  const setNestedProperty = (obj, path, value) => {
    let keys = path.split(".");
    let current = obj;

    while (keys.length > 1) {
      const [firstKey, ...restKeys] = keys;
      if (!current[firstKey]) {
        current[firstKey] = {};
      }
      current = current[firstKey];
      keys = restKeys;
    }

    current[keys[0]] = value;
  };
  const handleFieldChange = (field: string, value: any) => {
    const updatedPhase = { ...phase };

    if (field === "start" || field === "end") {
      updatedPhase[field] = value;
    } else {
      setNestedProperty(updatedPhase, field, value);
    }

    setPhase(updatedPhase);
    handleChange(updatedPhase);
  };
  useEffect(() => {
    if (initialTrigger)
      setTimeout(function () {
        handleChange(phase);
      }, 500);
    else setInitialTrigger(true);
  }, [phase]);
  if (!phase) return <Spin />;
  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
      className="singlePhase"
    >
      <Flex justify="space-between">
        <Space align="baseline">
          {isSubscription ? (
            <Space direction="vertical">
              <Form.Item
                style={{ marginBottom: 0 }}
                label={translate("subscriptions.fields.forever")}
              >
                <Switch
                  checkedChildren={translate("buttons.yes")}
                  unCheckedChildren={translate("buttons.no")}
                  onChange={() => {
                    phase &&
                      setPhase({
                        ...phase,
                        start_date: phase.start_date,
                        end_date:
                          phase.end_date == null ? phase.start_date : null,
                      });
                  }}
                  value={phase.end_date == null}
                />
              </Form.Item>
              {phase.end_date != null ? (
                <Form.Item>
                  <DatePicker.RangePicker
                    value={[
                      dayjs(phase.start_date as string),
                      dayjs(phase.end_date as string),
                    ]}
                    onChange={(dates: any) => {
                      handleRangeChange(dates);
                    }}
                  />
                </Form.Item>
              ) : (
                <>
                  <Form.Item>
                    <DatePicker value={dayjs(phase.start_date as string)} />
                  </Form.Item>
                </>
              )}
            </Space>
          ) : (
            <Space direction="vertical">
              <Text>{translate("subscriptions.list.duration")}:</Text>
              <Space align="baseline">
                <Form.Item>
                  <Select
                    value={
                      typeof phase.start === "string"
                        ? phase.start
                        : phase.start?.interval || "start"
                    }
                    onChange={(value) => {
                      if (value === BillingType.Start) {
                        handleFieldChange("start", BillingType.Start);
                        setDisableFromValue(true);
                      } else {
                        const intervalCount =
                          typeof phase.start === "object" &&
                          phase.start.interval_count
                            ? phase.start.interval_count
                            : 1;
                        handleFieldChange("start", {
                          interval: value,
                          interval_count: intervalCount,
                        });
                        setDisableFromValue(false);
                      }
                    }}
                    disabled={index === 0}
                  >
                    <Select.Option value={BillingType.Start}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Start}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Day}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Day}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Week}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Week}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Month}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Month}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Year}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Year}`
                      )}
                    </Select.Option>
                  </Select>
                </Form.Item>
                {!disableFromValue && (
                  <Form.Item>
                    <InputNumber
                      min={1}
                      onChange={(value) =>
                        handleFieldChange("start.interval_count", value)
                      }
                      value={
                        typeof phase.start === "object" &&
                        phase.start.interval_count
                          ? phase.start.interval_count
                          : 1
                      }
                    />
                  </Form.Item>
                )}
                <ArrowRightOutlined />
                <Space>
                  {!disableToValue && (
                    <Form.Item>
                      <InputNumber
                        min={1}
                        onChange={(value) =>
                          handleFieldChange("end.interval_count", value)
                        }
                        value={
                          typeof phase.end === "object" &&
                          phase.end.interval_count
                            ? phase.end.interval_count
                            : 1
                        }
                      />
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Select
                      value={
                        typeof phase.end === "string"
                          ? phase.end
                          : phase.end?.interval || "day"
                      }
                      onChange={(value) => {
                        if (value === BillingType.Forever) {
                          handleFieldChange("end", BillingType.Forever);
                          setDisableToValue(true);
                        } else {
                          const intervalCount =
                            typeof phase.end === "object" &&
                            phase.end.interval_count
                              ? phase.end.interval_count
                              : 1;
                          handleFieldChange("end", {
                            interval: value,
                            interval_count: intervalCount,
                          });
                          setDisableToValue(false);
                        }
                      }}
                    >
                      <Select.Option value={BillingType.Day}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Day}`
                        )}
                      </Select.Option>
                      <Select.Option value={BillingType.Week}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Week}`
                        )}
                      </Select.Option>
                      <Select.Option value={BillingType.Month}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Month}`
                        )}
                      </Select.Option>
                      <Select.Option value={BillingType.Year}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Year}`
                        )}
                      </Select.Option>
                      <Select.Option value={BillingType.Forever}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Forever}`
                        )}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Space>
              </Space>
            </Space>
          )}
        </Space>
      </Flex>
      <Space>
        <div>
          <Text>{translate("subscriptions.list.price", "Price")}:</Text>
          <Form.Item>
            <InputNumber
              addonAfter={currency}
              style={{ width: "100%" }}
              precision={2}
              onChange={(value) => handleFieldChange("price", value)}
              value={phase?.price && phase.price}
            />
          </Form.Item>
        </div>
        <div>
          <Text>{translate("subscriptions.list.tax_rate", "Tax rate")}:</Text>
          <Form.Item>
            <InputNumber
              addonAfter={"%"}
              style={{ width: "100%" }}
              precision={2}
              min={0}
              max={100}
              value={phase?.tax_rate && phase.tax_rate}
              onChange={(value) => handleFieldChange("tax_rate", value)}
            />
          </Form.Item>
        </div>
        {phase.end === BillingType.Forever && (
          <Space direction="vertical" style={{ gap: 0 }}>
            <Text>{translate("product_items.fields.invoice_interval")}:</Text>
            <Space>
              <Form.Item>
                <InputNumber
                  min={1}
                  value={
                    phase.billing_interval.interval_count
                      ? phase.billing_interval.interval_count
                      : 1
                  }
                  onChange={(value) =>
                    handleFieldChange("billing_interval.interval_count", value)
                  }
                />
              </Form.Item>
              <Form.Item>
                <Select
                  style={{ minWidth: 100 }}
                  value={
                    phase.billing_interval.interval
                      ? phase.billing_interval.interval
                      : "day"
                  }
                  onChange={(value) =>
                    handleFieldChange("billing_interval.interval", value)
                  }
                >
                  <Select.Option value={BillingType.Day}>
                    {translate(
                      `subscription_products.fields.billing_type.${BillingType.Day}`
                    )}
                  </Select.Option>
                  <Select.Option value={BillingType.Week}>
                    {translate(
                      `subscription_products.fields.billing_type.${BillingType.Week}`
                    )}
                  </Select.Option>
                  <Select.Option value={BillingType.Month}>
                    {translate(
                      `subscription_products.fields.billing_type.${BillingType.Month}`
                    )}
                  </Select.Option>
                  <Select.Option value={BillingType.Year}>
                    {translate(
                      `subscription_products.fields.billing_type.${BillingType.Year}`
                    )}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Space>
          </Space>
        )}
      </Space>
      <Table
        pagination={false}
        className="tableSinglePhase"
        footer={() => (
          <Flex justify="space-between" style={{ width: "100%" }}>
            <Space>
              {showSelectProduct && (
                <>
                  <Select
                    {...selectProps}
                    onSelect={handleSelectProduct}
                    style={{ width: 300 }}
                  />
                  <Button
                    type="default"
                    icon={<StopOutlined />}
                    onClick={() => {
                      setShowSelectProduct(false);
                      setNewProduct(undefined);
                    }}
                    style={{ fontSize: "0.80rem", padding: 7 }}
                  >
                    {translate("buttons.cancel")}
                  </Button>
                </>
              )}
              <Button
                type={showSelectProduct ? "primary" : "link"}
                icon={showSelectProduct ? <SaveOutlined /> : <PlusOutlined />}
                onClick={() =>
                  showSelectProduct
                    ? handleAddNewProduct()
                    : setShowSelectProduct(true)
                }
                style={{ fontSize: "0.80rem", padding: 7 }}
              >
                {showSelectProduct
                  ? translate("buttons.save")
                  : translate("product_items.buttons.add_product")}
              </Button>
            </Space>
          </Flex>
        )}
        dataSource={phase?.products?.map((item: any, index: number) => {
          const variantLabel = item.variant?.map((v) => v.value).join(", ");
          return {
            key: (index + 1).toString(),
            product_item: (
              <Space direction="vertical">
                <Text>
                  {`${item?.name} ${variantLabel ? `(${variantLabel})` : ""}`}
                </Text>
                <Text style={{ color: "rgba(140, 140, 140, 1)" }}>
                  <Popover
                    content={
                      <>
                        <Flex
                          vertical
                          style={{ width: "100%", marginRight: 5 }}
                          gap={5}
                        >
                          <Input.TextArea
                            cols={40}
                            rows={4}
                            value={editingDescription}
                            onChange={(e) =>
                              setEditingDescription(e.target.value)
                            }
                          />
                          <Button
                            type={"primary"}
                            size="small"
                            onClick={() => {
                              const newProductItems = [...phase.products];
                              newProductItems[index].description =
                                editingDescription;
                              setPhase({
                                ...phase,
                                products: newProductItems,
                              });
                              setEditingIndex(null);
                              setEditingDescription("");
                            }}
                          >
                            {translate("buttons.save")}
                          </Button>
                        </Flex>
                      </>
                    }
                    title={translate("product_items.fields.description")}
                    trigger="click"
                    open={editingIndex === index}
                    onOpenChange={(visible) => {
                      if (visible) {
                        setEditingIndex(index);
                        setEditingDescription(item.description);
                      } else {
                        setEditingIndex(null);
                        setEditingDescription("");
                      }
                    }}
                  >
                    {item.description ?? <i>Click here to add description</i>}
                  </Popover>
                </Text>
              </Space>
            ),
            issues: (
              <InputNumber
                value={item.issues}
                min={1}
                onChange={(value) => handleIssuesChange(index, value)}
              />
            ),
            action: (
              <Button
                type="link"
                onClick={() => handleDeleteProduct(index)}
                icon={<DeleteOutlined />}
                danger
              />
            ),
          };
        })}
      >
        <Table.Column
          dataIndex={"product_item"}
          title={translate("product_items.show.title")}
        />
        <Table.Column
          title={translate("product_items.show.issues")}
          dataIndex={"issues"}
        />
        <Table.Column
          title={translate("product_items.show.action")}
          dataIndex={"action"}
        />
      </Table>
    </Space>
  );
}
