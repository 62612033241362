import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import {
  CanAccess,
  useDelete,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Col,
  Collapse,
  Drawer,
  Flex,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { CollapseProps } from "antd/lib";
import dayjs from "dayjs";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Text } from "../../../components";
import SinglePhase from "../../../components/shop/single-phase";
import { useUser } from "../../../contexts/ContextProvider";

const SubscriptionProductsShow: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { currentTeam } = useUser();
  const { show } = useNavigation();
  const { id: prodId, subId } = useParams();
  const [fieldChange, setFieldChange] = useState(false);
  const { mutate: deleteMutation } = useDelete();

  const { formProps, form, query } = useForm({
    action: "edit",
    redirect: false,
    mutationMode: "pessimistic",
    id: subId,
    invalidates: ["all"],
  });
  const translate = useTranslate();
  const closeModal = () => {
    if (prodId) show("product_items", prodId);
  };
  const { id, phases, currency, created_at } = query?.data?.data ?? {};
  useEffect(() => {
    form.setFieldValue("phases", phases);
    setFieldChange(!fieldChange);
  }, [phases]);
  const handlePhaseDelete = useCallback(
    (index: number) => {
      const changedPhase = phases.filter(
        (_item, indexFilter) => index != indexFilter
      );
      form.setFieldValue("phases", changedPhase);
      form.submit();
    },
    [form, phases]
  );
  const handleChangesOnPhases = useCallback(
    (updatedPhase, index) => {
      const updatedPhases = phases.map((phase) => ({ ...phase })); // Create a deep copy of phases to avoid mutation

      // Update the current phase with the new values
      updatedPhases[index] = { ...updatedPhases[index], ...updatedPhase };

      // If `to` duration changed, sync `from` of the next phase
      if (updatedPhase.end && index < updatedPhases.length - 1) {
        updatedPhases[index + 1].start = updatedPhase.end;
      }

      // If `from` duration changed, sync `to` of the previous phase
      if (updatedPhase.start && index > 0) {
        updatedPhases[index - 1].end = updatedPhase.start;
      }

      form.setFieldValue("phases", updatedPhases);
      setFieldChange((prevFieldChange) => !prevFieldChange);
    },
    [form, phases]
  );

  const items: CollapseProps["items"] = useMemo(
    () =>
      form.getFieldValue("phases")?.map((item: any, index: number) => {
        return {
          key: (index + 1).toString(), // key should be a string
          label: translate("product_items.phase_nr") + " " + (index + 1),
          extra: (
            <Popconfirm
              title={translate("phases.title_single")}
              description={translate("phases.description_single")}
              onConfirm={() => {
                handlePhaseDelete(index);
              }}
              okText={translate("buttons.yes", "Yes")}
              cancelText={translate("buttons.no", "No")}
            >
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                style={{ fontSize: "0.80rem", padding: 7 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {translate("subscription_products.show.delete_phase")}
              </Button>
            </Popconfirm>
          ),
          children: (
            <SinglePhase
              handleChange={(phase) => handleChangesOnPhases(phase, index)}
              handlePhaseDelete={() => handlePhaseDelete(index)}
              data={item}
              currency={currency}
              index={index}
            />
          ),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currency,
      handleChangesOnPhases,
      handlePhaseDelete,
      phases,
      translate,
      fieldChange,
    ]
  );

  if (query?.isError) {
    closeModal();
    return null;
  }

  if (query?.isLoading && !currentTeam?.account_id) {
    return (
      <Drawer
        open
        styles={{
          body: {
            background: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        className="entityDrawer"
        destroyOnClose={true}
      >
        <Spin />
      </Drawer>
    );
  }

  const handleAddNewPhase = () => {
    const lastPhase = phases[phases?.length - 1];
    let newPhase;
    // Create a new phase that starts where the last phase ended
    if (lastPhase)
      newPhase = {
        ...lastPhase, // Copy all properties from the last phase
        key: lastPhase.key + 1, // Ensure the key is unique
        start: { ...lastPhase.end }, // Start from where the last phase ended
        end: { ...lastPhase.end }, // Keep the same duration or adjust as needed
        products: lastPhase.products.map((item) => ({ ...item })), // Deep copy product items
      };
    else
      newPhase = {
        key: 1,
        name: "Best deal ever!",
        price: 1,
        billing_interval: {
          interval: "month",
          interval_count: 3,
        },
        start: "start",
        end: {
          interval: "day",
          interval_count: 1,
        },
        tax_rate: 0,
        products: [],
      };
    const updatedPhases = [...phases, newPhase];
    form.setFieldValue("phases", updatedPhases);
    form.submit();
  };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Drawer
          open
          onClose={() => closeModal()}
          width={800}
          title={translate("subscription_products.show.title")}
          footer={
            <Flex justify="space-between" align="center">
              <Text className="ant-text tertiary">
                {translate("subscription_products.fields.created_at")}{" "}
                {dayjs(created_at).format(
                  translate("page.settings.date_format")
                )}{" "}
                {translate("page.settings.clock")}
              </Text>
              <div>
                <Popconfirm
                  title={translate(
                    "subscription_products.buttons.delete.title_single"
                  )}
                  description={translate(
                    "subscription_products.buttons.delete.description_single"
                  )}
                  onConfirm={() => {
                    if (id) {
                      deleteMutation(
                        {
                          id,
                          resource: "subscription_products",
                        },
                        {
                          onSuccess: () => closeModal(),
                        }
                      );
                    }
                  }}
                  okText={translate("buttons.yes", "Yes")}
                  cancelText={translate("buttons.no", "No")}
                >
                  <Button type="link" danger icon={<DeleteOutlined />}>
                    {translate(
                      "subscription_products.buttons.delete.title_single"
                    )}
                  </Button>
                </Popconfirm>
                <Button onClick={() => form.submit()} type="primary">
                  {translate("buttons.save")}
                </Button>
              </div>
            </Flex>
          }
        >
          <Form layout="vertical" {...formProps}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item
                  name={"name"}
                  label={translate("subscription_products.fields.title")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name={"description"}
                  label={translate("subscription_products.fields.description")}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label={translate(
                    "subscription_products.fields.earliest_cancellation_interval"
                  )}
                  name="earliest_cancellation_interval"
                  rules={[
                    {
                      required: true,
                      validator(_rule, value) {
                        if (
                          !value ||
                          !value?.interval_count ||
                          !value?.interval
                        ) {
                          return Promise.reject(
                            new Error(
                              "Please enter Earliest Cancellation Interval"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                      validateTrigger: ["onBlur", "onChange"],
                    },
                  ]}
                >
                  <Flex gap={20}>
                    <Form.Item
                      name={[
                        "earliest_cancellation_interval",
                        "interval_count",
                      ]}
                      noStyle
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      name={["earliest_cancellation_interval", "interval"]}
                      noStyle
                    >
                      <Select
                        options={[
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.day"
                            ),
                            value: "day",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.week"
                            ),
                            value: "week",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.month"
                            ),
                            value: "month",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.year"
                            ),
                            value: "year",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label={translate(
                    "subscription_products.fields.cancellation_interval"
                  )}
                  name={"cancellation_interval"}
                  rules={[
                    {
                      required: true,
                      validator(_rule, value) {
                        if (
                          !value ||
                          !value?.interval_count ||
                          !value?.interval
                        ) {
                          return Promise.reject(
                            new Error("Please enter Cancellation Interval")
                          );
                        }
                        return Promise.resolve();
                      },
                      validateTrigger: ["onBlur", "onChange"],
                    },
                  ]}
                >
                  <Flex gap={20}>
                    <Form.Item
                      name={["cancellation_interval", "interval_count"]}
                      noStyle
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      name={["cancellation_interval", "interval"]}
                      noStyle
                    >
                      <Select
                        options={[
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.day"
                            ),
                            value: "day",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.week"
                            ),
                            value: "week",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.month"
                            ),
                            value: "month",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.year"
                            ),
                            value: "year",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name={"currency"}
                  label={translate("subscription_products.fields.currency")}
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "EUR", value: "EUR" },
                      { label: "USD", value: "USD" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={translate(
                    "subscription_products.fields.notice_period"
                  )}
                  name={"notice_period"}
                  rules={[
                    {
                      required: true,
                      validator(_rule, value) {
                        if (
                          !value ||
                          !value?.interval_count ||
                          !value?.interval
                        ) {
                          return Promise.reject(
                            new Error("Please enter Notice Period")
                          );
                        }
                        return Promise.resolve();
                      },
                      validateTrigger: ["onBlur", "onChange"],
                    },
                  ]}
                >
                  <Flex gap={20}>
                    <Form.Item
                      name={["notice_period", "interval_count"]}
                      noStyle
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item name={["notice_period", "interval"]} noStyle>
                      <Select
                        options={[
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.day"
                            ),
                            value: "day",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.week"
                            ),
                            value: "week",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.month"
                            ),
                            value: "month",
                          },
                          {
                            label: translate(
                              "subscription_products.fields.billing_type.year"
                            ),
                            value: "year",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Flex>
                </Form.Item>
              </Col>
            </Row>

            <Space
              direction="vertical"
              size={"large"}
              style={{ width: "100%" }}
            >
              <Form.Item name={"phases"} noStyle>
                <Input hidden />
              </Form.Item>
              <Flex justify="space-between" align="center">
                <Typography.Title
                  level={5}
                  style={{
                    fontSize: "1.02em",
                  }}
                >
                  {translate("subscription_products.show.phases")}
                </Typography.Title>
                <Button
                  type="link"
                  style={{ fontSize: "0.80rem", fontWeight: 500, padding: 7 }}
                  icon={<PlusOutlined />}
                  onClick={handleAddNewPhase}
                >
                  {translate("subscription_products.list.add")}
                </Button>
              </Flex>

              {items && (
                <Collapse
                  items={items}
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                />
              )}
            </Space>
          </Form>
        </Drawer>
        {children}
      </>
    </CanAccess>
  );
};

export default SubscriptionProductsShow;
