import { useForm } from "@refinedev/antd";
import {
  CanAccess,
  useNavigation,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import { DatePicker, Form, Modal, Select } from "antd";
import dayjs from "dayjs";
import React, { PropsWithChildren, useState } from "react";
import AddressAutoselect from "../../../components/common/AddressAutoselect";
import ContactAutoSelect from "../../../components/contacts/ContactAutoSelect";
import { useUser } from "../../../contexts/ContextProvider";
import { useSubscriptionProductSelect } from "../../../hooks/useSubscriptionProductSelect";
import { supabaseClient } from "../../../utility";

const SubscriptionCreate: React.FC<PropsWithChildren> = ({ children }) => {
  const translate = useTranslate();
  const [selectedContact, setSelectedContact] = useState<string>("");
  const [deliveryContact, setDeliveryContact] = useState<string | undefined>(
    undefined
  );
  const { currentTeam } = useUser();
  const { list } = useNavigation();
  const { open } = useNotification();

  const { query: productResult, selectProps: phaseSelectProp } =
    useSubscriptionProductSelect();

  const { formProps, saveButtonProps } = useForm({
    resource: "subscriptions",
    action: "create",
    redirect: false,
  });

  const handleFinish = async ({ startDate, ...values }: any) => {
    const payload: any = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      subscriptionProductId: values.subscription_product,
      invoiceContactId: parseInt(selectedContact),
      invoiceContactAddressId: values.invoice_contact_address,
    };

    if (deliveryContact?.trim()) {
      payload.deliveryContactId = deliveryContact;
      if (values.delivery_contact_address) {
        payload.deliveryContactAddressId = values.delivery_contact_address;
      }
    }

    try {
      const { data, error } = await supabaseClient.functions.invoke(
        "shop-core/subscription/create-from-subscription-product",
        {
          body: {
            ...payload,
          },
          method: "POST",
        }
      );

      if (error) {
        console.log("error", data);
        open?.({
          description: translate("alerts.toast.error"),
          message: error?.error,
          type: "error",
        });
        console.error("Error creating subscription:", error);
      } else {
        open?.({
          description: translate("notifications.success"),
          message: translate("notifications.createSuccess"),
          type: "success",
        });
        list("subscriptions", "replace");
      }
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Modal
          open
          centered
          maskClosable={false}
          title={translate("subscriptions.create.title")}
          onCancel={() => {
            list("subscriptions", "replace");
          }}
          okText={translate("buttons.save")}
          okButtonProps={{
            ...saveButtonProps,
          }}
          width={760}
        >
          <Form {...formProps} layout="vertical" onFinish={handleFinish}>
            <ContactAutoSelect
              onSelect={(value) => {
                setSelectedContact(value);
              }}
              name="invoice_contact"
              initialValue={selectedContact}
              label={translate("subscriptions.fields.user")}
            />
            {selectedContact && (
              <AddressAutoselect
                name={"invoice_contact_address"}
                contactId={selectedContact}
                label="subscriptions.fields.invoice_contact_address"
              />
            )}
            <ContactAutoSelect
              onSelect={(value) => {
                setDeliveryContact(value);
              }}
              name="delivery_contact"
              initialValue={deliveryContact}
              label={translate("subscriptions.fields.delivery_contact")}
              required={false}
            />
            {deliveryContact && (
              <AddressAutoselect
                name={"delivery_contact_address"}
                contactId={deliveryContact}
                label="subscriptions.fields.delivery_contact_address"
              />
            )}
            <Form.Item
              name={"subscription_product"}
              label={translate("subscriptions.fields.subscription_product")}
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: "100%" }}
                {...phaseSelectProp}
                options={productResult.data?.data.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
            <Form.Item
              label={translate("subscriptions.fields.start_date")}
              name="startDate"
              initialValue={dayjs()}
            >
              <DatePicker format={translate("global.date_format")} />
            </Form.Item>
          </Form>
        </Modal>
        {children}
      </>
    </CanAccess>
  );
};

export default SubscriptionCreate;
