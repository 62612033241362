import { PlusOutlined } from "@ant-design/icons";
import { List, useTable } from "@refinedev/antd";
import {
  BaseKey,
  CanAccess,
  useDeleteMany,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Button, Flex, Popconfirm, Spin, Table, Typography } from "antd";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../contexts/ContextProvider";

const SubscriptionProductsList: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const navigate = useNavigate();
  const { replace } = useNavigation();

  const { currentTeam } = useUser();
  const { id } = useParams();

  const { tableProps, tableQuery } = useTable({
    resource: "subscription_products",
    sorters: {
      initial: [
        {
          field: "id",
          order: "desc",
        },
      ],
    },
    syncWithLocation: true,
    filters: {
      permanent: [
        {
          field: "account",
          value: currentTeam?.account_id,
          operator: "eq",
        },
        {
          field: "product_item",
          value: id,
          operator: "eq",
        },
      ],
    },
    pagination: { pageSize: 999 },
  });

  const { mutate } = useDeleteMany();

  const handleDeleteBulk = () => {
    mutate({
      resource: "subscription_products",
      ids: selectedRowKeys as BaseKey[],
    });
    setSelectedRowKeys([]);
  };

  const translate = useTranslate();

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [tableQuery.data?.data]);
  const handleRowClick = (event: any, id: any) => {
    if (event.target.className !== "ant-checkbox-input")
      navigate("subscription-products/show/" + id);
  };

  if (!currentTeam || !currentTeam?.account_id || tableProps.loading) {
    return <Spin />;
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <List
          title={false}
          breadcrumb={false}
          headerButtons={({ defaultButtons }) => (
            <>
              {selectedRowKeys.length != 0 && (
                <Popconfirm
                  title={translate(
                    "subscription_products.buttons.delete.title"
                  )}
                  description={translate(
                    "subscription_products.buttons.delete.description"
                  )}
                  onConfirm={handleDeleteBulk}
                  okText={translate("buttons.yes", "Yes")}
                  cancelText={translate("buttons.no", "No")}
                >
                  <Button type="primary" danger>
                    {translate("subscription_products.buttons.delete.selected")}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        >
          <Flex justify="space-between" align="center">
            <Typography.Title
              level={5}
              style={{
                fontSize: "1.02em",
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              {translate("subscription_products.list.title")}
            </Typography.Title>
            <Button
              icon={<PlusOutlined />}
              type="link"
              onClick={() => replace("subscription-products/create")}
            >
              {translate("subscription_products.buttons.add_product")}
            </Button>
          </Flex>
          <Table
            {...tableProps}
            rowKey={(record) => record.id ?? ""}
            rowClassName={"clickableRow"}
            loading={currentTeam === undefined}
            onRow={(record) => {
              return {
                onClick: (event) => handleRowClick(event, record.id),
              };
            }}
            pagination={false}
            rowSelection={rowSelection}
          >
            <Table.Column
              dataIndex="name"
              title={translate("subscription_products.fields.title")}
            />

            <Table.Column
              title={translate("subscription_products.fields.description")}
              dataIndex="description"
            />
          </Table>
        </List>
        {children}
      </>
    </CanAccess>
  );
};
export default SubscriptionProductsList;
